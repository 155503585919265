<template>
  <h1 v-if="h === 1">
    {{ title }}
  </h1>
  <h2 v-if="h === 2">
    {{ title }}
  </h2>
</template>

<script>
export default {
  props: ['title', 'h']
}
</script>

<style scoped>
h1, h2 {
  font-family: "Amatic SC";
  font-weight: 700;
  font-size: 100px;
  text-align: center;
  text-transform: uppercase;
}

h1 {
  font-size: 100px;
  margin: 0px 0px 40px;
}

h2 {
  font-size: 60px;
  margin: 0;
}

@media (max-width: 640px) {
  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 30px;
  }
}
</style>
