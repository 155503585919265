<template>
      <section class="info background-first" id="form">
        <TitleComponent title="ПРИХОДИ К НАМ РАБОТАТЬ, ДАРАГОЙ ГЕНАЦВАЛЕ!" :h="1"/>
        <div class="container">
          <div class="card">
            <form @submit.prevent="submitForm" v-if="popup === false">
              <div>
                <h3 class="input_title">Имя Фамилия</h3>
                <input type="text" id="name" v-model="name" placeholder="Иван Иванович" autocomplete="off" require/>
              </div>
              <div>
              <h3 class="input_title">Возраст(<small>Только с 18+</small>)</h3>
              <input type="text" id="name" v-model="age" placeholder="20" autocomplete="off" require/>
              </div>
              <div>
              <h3 class="input_title">Телефон</h3>
              <input type="text" id="name" v-model="phone" placeholder="89999999999" autocomplete="off" require/>
              </div>
              <div>
                <h3 class="input_title">Вакансия</h3>
                <select v-model="work" require>
                  <option v-for="(option, index) in optionArr" :key="index" :value="option">{{ option }}</option>
                </select>
              </div>
              <div>
              <h3 class="input_title">Резюме</h3>
              <input type="text" id="name" v-model="cv" placeholder="Ссылка на резюме" autocomplete="off" require/>
              </div>
              <button type="submit" :disabled="age < 18">Отправить резюме</button>
              <span>Нажимая на кнопку "Отправить", вы соглашаетесь с <a href="https://djos.rest/privacy" target="_blank">Политикой конфиденциальности</a> и даете своё согласие на <a href="https://djos.rest/consent_personal_data" target="_blank">обработку персональных данных</a>.</span>
            </form>
            <TitleComponent v-else :title="popupText" :h="2"/>
          </div>
        </div>
      </section>
</template>

<script>
import TitleComponent from './TitleComponent'
import axios from 'axios'

export default {
  data () {
    return {
      // form variables
      name: '',
      age: '',
      phone: '',
      cv: '',
      work: '',
      // option var
      optionArr: ['Официант', 'Хостес', 'Бармен', 'Повар', 'Технический работник'],
      // popup
      popup: false,
      popupText: ''
    }
  },
  components: {
    TitleComponent
  },
  methods: {
    submitForm () {
      console.group('form data')
      console.log(`Name: ${this.name}`)
      console.log(`Age: ${this.age}`)
      console.log(`Phone: ${this.phone}`)
      console.log(`CV: ${this.cv}`)
      console.log(`Work: ${this.work}`)
      console.groupEnd()

      let msg = `<b>Новый ${this.work} в ХВАНЧ г. Пенза</b>\n`
      msg += '\n'
      msg += `<b>Имя</b>: ${this.name}\n`
      msg += `<b>Возраст</b>: ${this.age}\n`
      msg += `<b>Телефон</b>: ${this.phone}\n`
      msg += `<b>Ссылка на резюме</b>: ${this.cv}\n`
      msg += '\n'
      const date = new Date()
      msg += `<i>Время отправки отзыва: ${date.getHours()}:${date.getMinutes()}</i>\n`
      msg += `<i>Дата отправки заявки: ${date.getDate()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getFullYear()}</i>`

      axios.post(process.env.VUE_APP_URL_TELEGRAM, {
        chat_id: process.env.VUE_APP_CHAT_ID,
        parse_mode: 'html',
        text: msg
      })

      const formData = {
        name: this.name,
        age: this.age,
        phone: this.phone,
        cv: this.cv,
        work: this.work,
        time: `${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`,
        date: `${date.getDate()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getFullYear()}`
      }

      axios.post(process.env.VUE_APP_GOOGLE_TABLE, formData).then((response) => console.log(response.data))
      // open popup info
      this.popup = true
      this.popupText = 'Резюме отправлено! Скоро с вами свяжутся'
      // clear fields
      this.name = ''
      this.age = ''
      this.phone = ''
      this.cv = ''
      this.work = ''
    }
  },
  watch: {
    phone () {
      this.phone = this.phone.replace(/[a-zа-яё]/gi, '')

      if (this.phone[0] === '7') {
        this.phone = '8' + this.phone.slice(1) // Replace '7' with '8' at the beginning of the string
      }

      if (this.phone.length > 11) {
        this.phone = ''
      }
    }
  }
}
</script>

<style scoped>
form {
  margin: 30px;
  display: flex;
  flex-direction: column;
}

h3 {
  margin: 10px 0px;
}

input, select {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  background-color: white;
  padding: 0px 10px;
  border: 2px solid black;
  border-radius: 20px;
  font-size: 20px;
  color: black;
  font-family: PT Sans Narrow;
}

.input_title {
  font-size: 24px;
  font-weight: 500;
  color: #000;
}

button {
  bottom: 320px;
  width: fit-content;
  box-sizing: border-box;
  margin: 20px auto 20px;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
  height: 50px;
  color: #000000;
  border: 0px;
  border-radius: 30px;
  font-size: 22px;
  font-family: PT Sans Narrow;
  cursor: pointer;
  background-color: #e6ff00;
  transition: 1s all easy-in;
}

button:disabled {
  background-color: grey;
  cursor: default;
}

span {
  margin: 0px auto;
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

span > a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

span > a:hover {
  color: #64dcc3;
}

@media (max-width: 640px) {
  button {
    font-size: 18px;
  }
}
</style>
