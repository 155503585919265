<template>
  <div class="video-container">
      <!-- <img v-if="isMobile" class="img-gif" src="../assets/video/ezgif.gif" alt="Анимированная GIF"> -->
      <img v-if="isMobile" class="img-gif" src="../assets/video/video4old.gif" alt="Анимированная GIF">
      <video v-else ref="video" autoplay loop muted playsinline>
        <source src="../assets/video/video.mp4" type="video/mp4">
      </video>
      <div class="title_info">
        <a href="https://hvanch.ru/"><img class="img-logo" src="../assets/logo.png" alt="Логотип"></a>
        <a href="/#form" class="btn">Заполнить анкету</a>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    videoSource: String
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    this.isMobile = this.detectMobileDevice()
  },
  methods: {
    detectMobileDevice () {
      return window.innerWidth <= 900 // Пример, измените это на более точный тест
    }
  }
}
</script>

<style scoped>
.video-container {
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

video source {
  object-fit: cover;
}

.title_info {
  width: fit-content;
  height: auto;
  margin: 0 auto;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.img-gif {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.img-logo {
  width: 200px;
  height: auto;
  object-fit: container;
}

.btn {
  width: fit-content;
  box-sizing: border-box;
  margin: 0;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
  height: 50px;
  color: #000000;
  border: 0px;
  border-radius: 30px;
  font-size: 22px;
  font-family: PT Sans Narrow;
  cursor: pointer;
  background-color: #e6ff00;
  z-index:3;
  text-decoration: none;
}

@media (max-width: 640px) {
  .btn {
    font-size: 18px;
  }
}

/* @media (max-width: 960px) {
  .btn {
    bottom: 220px;
  }

  .img-logo {
    bottom: 240px;
  }
} */

</style>
