<template>
  <footer id="contacts">
    <iframe
      src="https://yandex.ru/map-widget/v1/?um=constructor%3A65f2c6fdd437314642a127cf25eb18087aea8446b1b0e907e292fe74e92683a2&amp;source=constructor"
      width="100%"
      height="400"
      frameborder="0"
      hspace="0"
    ></iframe>
    <div class="footer_info">
      <div class="container">
        <a href="https://hvanch.ru/"><img class="img-logo" src="../assets/black_logo.svg" alt="hvanch logo"/></a>
        <a href="https://vk.com/hvanch_penza" target="_blank"><img src="../assets/img/vk_logo_PERK.svg" alt="vk logo"/></a>
        <a v-if="isMobile" href="tel:+7 (937) 817-71-77">Трудоустройство: +7 (937) 817-71-77</a>
        <a v-else href="tel:+7 (937) 817-71-77"><img src="../assets/img/vk_logo_PHONE.svg" alt="phone logo"/></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  mounted () {
    this.isMobile = this.detectMobileDevice()
  },
  methods: {
    detectMobileDevice () {
      return window.innerWidth <= 900
    }
  }
}
</script>

<style scoped>
footer {
  height: fit-content;
  position: relative;
}

.footer_info > .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_info > .container > a {
  font-size: 30px;
  font-weight: 300;
  color: #000;
}

.footer_info > .container > a:hover {
  color: white;
}

@media (max-width: 640px) {
  .footer_info {
    height: fit-content !important;
  }

  .footer_info > .container > a {
    font-size: 16px;
  }
}

@media (max-width: 960px) {
  iframe {
    height: 300px;
  }
}

.footer_info {
  width: 100%;
  height: 60px;
  background-color: #64DCC3;
  background-image: url('../assets/pattern/pattern3.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 20px 0px;
}

iframe {
  overflow: hidden;
  border: 0;
}

.img-logo {
  width: 100px;
  height: auto;
  object-fit: container;
}

img {
  width: 60px;
  height: 60px;
}
</style>
