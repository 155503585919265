<template>
    <VideoComponent :videoSource="yourVideoSource" />
    <LegendComponent />
    <FormComponent />
    <FooterComponent />
</template>

<script>
import VideoComponent from './components/VideoComponent'
import LegendComponent from './components/LegendComponent'
import FormComponent from './components/FormComponent'
import FooterComponent from './components/FooterComponent'

export default {
  components: {
    VideoComponent,
    LegendComponent,
    FormComponent,
    FooterComponent
  },
  data () {
    return {
      yourVideoSource: '../assets/video/video.mp4'
    }
  }
}
</script>

<style>
.container {
    width: 1300px;
    margin: 0 auto;
}

.contain-div {
  height: fit-content;
}

section {
  width: 100%;
  height: 100%;
  padding-top: 40px;
  position: relative;
}

.info {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
}

.background-first {
  background-image: url('./assets/img/podloshkajpeg.png');
}

.background-second {
  background-image: url('./assets/img/podloshkajpeg-2.png');
}

.image_maskot {
  width: 46%;
  height: auto;
  object-fit: cover;
}

.side_maskot {
  width: 50%;
  height: fit-content;
}

.description {
  font-size: 30px;
  line-height: 1.2;
  margin: 0;
}
</style>
