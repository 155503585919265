<template>
  <section class="info background-second" id="legend">
    <TitleComponent title="Легенда о доме Хванч" :h="1"/>
    <div class="container">
      <div class="card flex height-fixed" :class="isMobile === true ? 'flex-mobile' : 'flex'">
        <img v-if="!isMobile" src="../assets/img/djos.png" alt="hvanch_person" class="maskot"/>
        <!-- <img v-else src="../assets/img/djos.png" alt="hvanch_person" class="maskot_mobile"/> -->
        <div class="text">
          <p class="description">Гамарджоба, дарагой! Меня зовут Хванч, и это мой грузинский ресторан!</p>
          <br />
          <p class="description">Вместе с моей женой Сулико мы очень любим встречать в нашем доме сердешных генацвале, угощать их вкусношвильными блюдами и веселидзе вместе с ними.</p>
          <br />
          <p class="description">Будьте уверены, вам у нас понравится, ведь дом Хванч – это праздник каждый день!  Асса!</p>
          <br />
          <p class="description" style="margin-bottom: 20px;">А ещё... У меня есть брат-близнец, но это совсем другая история...</p>
          <br />
          <a href="/#form" class="btn">Узнать продолжение истории</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TitleComponent from './TitleComponent'

export default {
  components: {
    TitleComponent
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    this.isMobile = this.detectMobileDevice()
  },
  methods: {
    detectMobileDevice () {
      return window.innerWidth <= 960 // Пример, измените это на более точный тест
    }
  }
}
</script>

<style scoped>
.text {
  margin: 30px;
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-mobile {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
}

.height-fixed {
  max-height: 700px;
}

.maskot {
  box-sizing: border-box;
  padding-top: 40px;
  height: 700px;
  width: auto;
  object-fit: cover;
}

.maskot_mobile {
  height: 200px;
  width: auto;
  object-fit: cover;
}

.btn {
  width: fit-content;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 10px 30px;
  margin: 0px auto;
  text-align: center;
  font-weight: 600;
  height: auto;
  color: #000000;
  border: 0px;
  border-radius: 30px;
  font-size: 22px;
  font-family: PT Sans Narrow;
  cursor: pointer;
  background-color: #e6ff00;
  text-decoration: none;
}

@media (max-width: 640px) {
  .height-fixed {
    max-height: none;
  }

  .btn {
    font-size: 18px;
  }
}
</style>
